// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("@orange-ui/orange/dist/js/orange.js")

import Splide from '@splidejs/splide';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('DOMContentLoaded', () => {
  const mobile_nav_button = document.querySelector('.js--open-nav')
  const header = document.querySelector('.header')

  // Toggle '.is-opened' class to Header
  mobile_nav_button.addEventListener("click", () => {
    header.classList.contains('is-opened') ? 
    header.classList.remove('is-opened') :
    header.classList.add('is-opened')
  })

  // Init Anatomy Slider
  window.splide1 = new Splide( '.js--slider-anatomy', {
    perPage     : 1,
    type        : 'fade',
    autoplay    : true,
    rewind      : true,
    interval    : 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows      : false,
    pagination  : false,
    drag        : false,
  }).mount();
  
  // Init Screenshots Slider
  new Splide( '.js--slider-screenshots', {
    focus             : 'center',
    perPage           : 2,
    trimSpace         : false,
    type              : 'loop',
    autoplay          : true,
    interval          : 6000,
    pauseOnHover      : false,
    pauseOnFocus      : true,
    arrows            : true,
    pagination        : false,
    gap               : 40,
    clones            : 3,
    waitForTransition : false,
    resetProgress     : true,
  }).mount();

  // Anatomy slider navigation
  document.querySelector('.slide-nav-0').addEventListener('click', function() {
    splide1.go(0)
    splide1.Components.Autoplay.pause()
  })
  
  document.querySelector('.slide-nav-1').addEventListener('click', function() {
    splide1.go(1)
    splide1.Components.Autoplay.pause()
  })
  
  var slideNavContainer = document.querySelector('.section--modes')
  var slideNavClassName = new String(slideNavContainer.className)
  slideNavContainer.className = slideNavClassName + ' slide-current-' + 0

  splide1.on('move', function() {
  	var index = splide1.index
  	slideNavContainer.className = slideNavClassName + ' slide-current-' + index
  })
})
